import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"

const Contact = ({ intl }) => (
  <Layout>
    <SEO title="Contact"/>
    <div className="hero-image">
      <p className="hero-image__text">{intl.formatMessage({ id: "nav.contact" })}</p>
      <StaticImage className="hero-image__image hero-image__image--dark" src="../images/shop/outside.jpg" alt={intl.formatMessage({ id: "image-outside" })}/>
    </div>
    <div className="contact-container">
      <div className="contact-card-container">
        <div className="card">
          <div className="contact-map">
            <iframe title="map" className="contact-map__frame" src={intl.formatMessage({ id: "map" })} frameBorder="0" allowFullScreen=""></iframe>
          </div>
          <div className="contact-info">
            <p className="contact__title">Punjab Sweets</p>
            <p className="contact__address">
              975 Rue Jean-Talon O, Montréal, QC H3N 1S9
            </p>
            <h3 className="contact__subtitle">{intl.formatMessage({ id: "opening-hours.title" })}</h3>
            <ul className="contact__ul">
              <li className="contact__li contact__text">
                <p className="contact__opening">{intl.formatMessage({ id: "opening-hours.days" })}</p>
                <p className="contact__opening">{intl.formatMessage({ id: "opening-hours.short-hours" })}</p>
              </li>
            </ul>
            <h4 className="button contact__phone"><a className="contact__link" href="tel:5142733000">514-273-3000</a></h4>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default injectIntl(Contact)
